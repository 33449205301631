<template>
  <div class="billingUpload">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">Update Sorted Billing Information</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card v-if="weeksCommencing.length > 0" :loading="loading" class="mb-4">
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="weekCommencing"
                :items="weeksCommencing"
                item-text="week_commencing"
                label="Week Commencing"
                outlined
                dense
                @change="getSortedData"
              ></v-select>
            </v-col>
            <v-col v-if="data.length > 0" cols="6">
              <v-btn
                class="mx-2 white--text"
                color="#B22222"
                @click="deleteAll()"
              >
                Delete All Entries for Week Commencing: {{ weekCommencing }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <div v-if="data.length > 0">
        <div class="billingTable">
          <v-card>
            <v-card-title>
              Billing Data
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="data"
              :items-per-page="5"
              :search="search"
              id="data-table"
            >
              <template v-slot:item="row">
                <tr>
                  <td>{{ row.item.company_code }}</td>
                  <td>{{ row.item.electioReference }}</td>
                  <td>{{ row.item.orderReference }}</td>
                  <td>{{ row.item.state }}</td>
                  <td>{{ row.item.carrier_name }}</td>
                  <td>{{ row.item.service }}</td>
                  <td>{{ row.item.dateShipped }}</td>
                  <td>{{ row.item.totalCost }}</td>
                  <td>{{ row.item.numberOfPackages }}</td>
                  <td>{{ row.item.week_commencing }}</td>
                  <td>
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      small
                      color="#B22222"
                      @click="deleteEntry(row.item)"
                    >
                      <v-icon dark>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </div>
    </div>
    <v-snackbar v-model="snackbar" :timeout="5000">
      {{ text }}
      <v-btn color="blue" text @click="snackbar = false"></v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import SortedUpload from "@/services/SortedUpload.js";

export default {
  name: "EditSortedData",
  data() {
    return {
      menu: false,
      // Snackbar
      snackbar: false,
      text: "",
      response: null,

      file: "",
      search: "",
      loading: false,

      data: [],
      valid: false,
      weekCommencing: null,
      weeksCommencing: [],

      headers: [
        { text: "Company Code", align: "left", value: "company_code" },
        { text: "Electio Reference", align: "left", value: "electioReference" },
        { text: "Order Reference", align: "left", value: "orderReference" },
        { text: "State", align: "left", value: "state" },
        { text: "Carrier", align: "left", value: "carrier_name" },
        { text: "Service", align: "left", width: "15%", value: "service" },
        { text: "Date Shipped", align: "left", value: "dateShipped" },
        { text: "Total Cost", align: "left", value: "totalCost" },
        { text: "Nº Packages", align: "left", value: "numberOfPackages" },
        { text: "Week Commencing", align: "left", value: "week_commencing" },
      ],
    };
  },
  methods: {
    async getWeeksCommencing() {
      // Use the BillingSnap to call the getBillingData() method
      return SortedUpload.getSortedWeeksCommencing()
        .then(
          ((weeksCommencing) => {
            this.$set(this, "weeksCommencing", weeksCommencing);
            return true;
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return false;
        });
    },
    async getSortedData() {
      SortedUpload.getSortedBillingData(this.weekCommencing)
        .then(
          ((data) => {
            this.$set(this, "data", data);
            this.loading = false;
            this.snackbar = true;
            this.text = `Successfully Loaded Sorted Data`;
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return;
        });
    },

    async loadInitialData() {
      this.data = [];
      this.weeksCommencing = [];
      this.weekCommencing = "";
      this.loading = true;
      let weeksCommencing = await this.getWeeksCommencing();
      if (weeksCommencing) {
        this.loading = false;
        this.text = `Select a week.`;
        this.snackbar = true;
      }
    },
    deleteEntry(entry) {
      if (
        confirm(
          "Confirm Deletion of Entry with Order Ref: " + entry.orderReference
        )
      ) {
    
        SortedUpload.deleteSortedByID(entry.id).then(
          () => {this.getSortedData()}
        );
      }
    },
    deleteAll() {
      if (
        confirm(
          "Confirm Deletion for All Entries for Week Commencing: " +
            this.weekCommencing
        )
      ) {
        SortedUpload.deleteAllSorted(this.weekCommencing).then(
          () => {this.loadInitialData()}
        );
      }
    },
  },
  created() {
    this.loadInitialData();
  },
  watch: {
    query: {
      handler(newVal) {
        if (newVal.week_commencing) {
          this.datesPicked = [];
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.description {
  margin-bottom: 30px;
}
.error {
  background-color: red;
}
</style>